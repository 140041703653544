import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from 'date-fns'

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  aWeekAgo: startOfDay(addDays(new Date(), -7)),
  aMonthAgo: startOfDay(addMonths(new Date(), -1)),
  aYearAgo: startOfDay(addMonths(new Date(), -12)),
  aLongTimeAgo: startOfDay(addYears(new Date(), -20)),
}

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range()
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
  },
}

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }))
}

export const customRanges = createStaticRanges([
  // {
  //   label: 'Today',
  //   range: () => ({
  //     startDate: defineds.startOfToday,
  //     endDate: defineds.endOfToday,
  //   }),
  // },
  // {
  //   label: 'Yesterday',
  //   range: () => ({
  //     startDate: defineds.startOfYesterday,
  //     endDate: defineds.endOfYesterday,
  //   }),
  // },

  {
    label: 'This Week',
    range: () => ({
      // startDate: defineds.startOfWeek,
      // endDate: defineds.endOfWeek,
      startDate: defineds.aWeekAgo,
      endDate: defineds.endOfToday,
    }),
  },
  // {
  //     label: 'Last Week',
  //     range: () => ({
  //         startDate: defineds.startOfLastWeek,
  //         endDate: defineds.endOfLastWeek,
  //     }),
  // },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.aMonthAgo,
      endDate: defineds.endOfToday,
    }),
  },
  // {
  //     label: 'Last Month',
  //     range: () => ({
  //         startDate: defineds.startOfLastMonth,
  //         endDate: defineds.endOfLastMonth,
  //     }),
  // },
  {
    label: 'This Year',
    range: () => ({
      startDate: defineds.aYearAgo,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'All Time',
    range: () => ({
      startDate: defineds.aLongTimeAgo,
      endDate: defineds.endOfToday,
    }),
  },
])

export const customInputRanges = [
  //    {
  //     label: 'days up to today',
  //     range(value) {
  //         return {
  //             startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
  //             endDate: defineds.endOfToday,
  //         };
  //     },
  //     getCurrentValue(range) {
  //         if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
  //         if (!range.startDate) return '∞';
  //         return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
  //     },
  // },
]
