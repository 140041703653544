import PressPage from '@components/PressPage'
import { graphql } from 'gatsby'

export const query = graphql`
  query($filter: ContentfulNewNewsArticleFilterInput!) {
    types: allContentfulPressPageTypes(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          title
          ignoreTopFeatured
          topFeatured {
            ...PostData
          }
          types {
            title

            slug
          }
        }
      }
    }

    posts: allContentfulNewNewsArticle(sort: { fields: [publishDate], order: DESC }, filter: $filter) {
      edges {
        node {
          ...PostData
        }
      }
    }
  }

  fragment PostData on ContentfulNewNewsArticle {
    slug
    title
    publishDate(formatString: "MMM D, YYYY")
    types {
      title
      slug
    }
    content {
      json
    }
    excerpt: content {
      json
    }
    image {
      description
      file {
        url
      }
    }
    vimeoUrl
  }
`

export default PressPage

// query($filter: ContentfulNewNewsArticleFilterInput!) {
//   types: allContentfulPressPageTypes(sort: { fields: [updatedAt], order: DESC }) {
//     edges {
//       node {
//         title
//         topFeatured {
//           ...PostData
//         }
//         types {
//           title
//           slug
//           featured {
//             ...PostData
//           }
//         }
//       }
//     }
//   }

// featured {
//   ...PostData
// }
