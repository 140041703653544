import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { renderToString } from 'react-dom/server'
import { TransitionState } from 'gatsby-plugin-transition-link'
import truncate from 'truncate-html'
import classnames from 'classnames/bind'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { DateRangePicker } from 'react-date-range'
import { addYears, endOfDay, startOfDay } from 'date-fns'

import Layout from '@components/Layout'
import { LAYOUT_STYLE } from '../Layout/constants'
import { richTextOptions } from '@components/Markdown'
import PressArticle from '@components/PressArticle'
import { customRanges, customInputRanges } from './customRanges.js'

import styles from './PressPage.module.scss'
const cx = classnames.bind(styles)

const PressPage = ({ data, pageContext, location, transitionStatus }) => {
  const { detail: isDetailPage, title: detailPageTitle } = pageContext

  const pageSlug = pageContext.slug

  const [inPress, setInPress] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [datePickerHidden, setDatePickerHidden] = useState(true)
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfDay(addYears(new Date(), -1)),
      endDate: endOfDay(new Date()),
      key: 'selection',
    },
  ])

  useEffect(() => {
    const prevPath = location && location.state && location.state.prevPath
    setInPress(prevPath && prevPath.indexOf('press') !== -1)
  }, [])

  useEffect(() => {
    if (transitionStatus === 'entering' && pageHash !== '') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [transitionStatus])

  const types = data && data.types && data.types.edges[0] && data.types.edges[0].node

  let pageHash = ''
  if (typeof window !== `undefined` && location && location.hash) {
    pageHash = location.hash.replace('#', '')
  }
  const getHash = () => {
    let target = ''
    if (!isDetailPage && pageSlug) {
      target = `#${pageSlug}`
    }

    if (isDetailPage && pageHash) {
      target = `#${pageHash}`
    }

    return target
  }

  const buildArticle = (node, isFeatured, isDetailArticle) => {
    const content = node.content ? documentToReactComponents(node.content.json, richTextOptions) : ''
    const targetUrl = `/news/${node.slug}${getHash()}`

    return (
      <PressArticle
        key={node.slug}
        className={cx('article', { main: isFeatured })}
        title={node.title}
        date={node.publishDate}
        types={node.types}
        image={node.image}
        richExcerpt={!isDetailArticle ? truncate(renderToString(content), isFeatured ? 900 : 320) : ''}
        richText={isFeatured && isDetailPage && renderToString(content)}
        featured={isFeatured}
        url={targetUrl}
        vimeoUrl={node.vimeoUrl}
        cta={
          isFeatured && !isDetailPage
            ? {
                label: 'Read More',
                url: targetUrl,
              }
            : null
        }
      />
    )
  }

  const formatDate = date => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const monthName = months[date.getMonth()]
    return monthName + ' ' + date.getDate() + ', ' + date.getFullYear()
  }

  const togglePickerVisibility = () => {
    const datePickerElements = document.getElementsByClassName('dp-identifier')
    try {
      if (datePickerHidden) {
        datePickerElements[0].style.display = 'flex'
      } else {
        datePickerElements[0].style.display = 'none'
      }
    } catch (e) {
      console.log(e)
    }
    setDatePickerHidden(!datePickerHidden)
  }

  const findPageFeatured = index => {
    let typeFeatured
    if (!isDetailPage && !pageSlug) {
      if (!data.types.edges[0].node.ignoreTopFeatured) {
        typeFeatured = data.types.edges[0].node.topFeatured
      } else {
        let counter = index
        while (!typeFeatured) {
          if (
            new Date(data.posts.edges[counter].node.publishDate) >= new Date(dateRange[0].startDate) &&
            new Date(data.posts.edges[counter].node.publishDate) <= new Date(dateRange[0].endDate) &&
            Array.isArray(data.posts.edges[counter].node.types) &&
            !data.posts.edges[counter].node.types.some(type => type.title.toLowerCase() === 'innovations')
          ) {
            typeFeatured = data.posts.edges[counter].node
          } else {
            counter += 1
          }
        }
      }
    }

    if (!isDetailPage && pageSlug) {
      let counter = index
      try {
        while (!typeFeatured) {
          if (
            new Date(data.posts.edges[counter].node.publishDate) >= new Date(dateRange[0].startDate) &&
            new Date(data.posts.edges[counter].node.publishDate) <= new Date(dateRange[0].endDate) &&
            Array.isArray(data.posts.edges[counter].node.types) &&
            !data.posts.edges[counter].node.types.some(type => type.title.toLowerCase() === 'innovations')
          ) {
            typeFeatured = data.posts.edges[counter].node
          } else {
            counter += 1
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    if (typeFeatured) {
      featuredPosts.push(buildArticle(typeFeatured, true, false))
      return typeFeatured
    }
    return
  }

  const detailPost = []
  const featuredPosts = []
  const nonFeaturedPosts = []

  const pageFeatured = findPageFeatured(0)
  const excludeSlug = pageFeatured && pageFeatured.slug

  data.posts &&
    data.posts.edges
      .filter(node => {
        try {
          if (
            (!node.node.types.some(type => type.title.toLowerCase() === 'innovations') &&
              (node.node.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                node.node.content.json.content[0].content[0].value.toLowerCase().includes(searchTerm.toLowerCase())) &&
              new Date(node.node.publishDate) >= new Date(dateRange[0].startDate) &&
              new Date(node.node.publishDate) <= new Date(dateRange[0].endDate)) ||
            node.node.slug === pageSlug
          ) {
            return node
          }
        } catch (e) {
          console.log(e)
        }
      })
      .map(({ node }) => {
        if (node.slug === excludeSlug) {
          return
        }
        const isDetailPost = isDetailPage && pageSlug === node.slug
        const target = buildArticle(node, isDetailPost, isDetailPost)
        if (isDetailPost) {
          detailPost.push(target)
        } else {
          nonFeaturedPosts.push(target)
        }

        return target
      })

  const posts = [...detailPost, ...featuredPosts, ...nonFeaturedPosts]
  // const articles = posts
  // if (articles[0].key == 'null') {
  //   posts.shift()
  //   findPageFeatured(1)
  // }

  const links = [
    {
      label: types.title,
      path: '/news',
      active: (!pageSlug && pageHash === '') || (isDetailPage && pageHash === ''),
    },
  ]
  types.types.map(_ => {
    links.push({
      label: _.title,
      path: _.slug && `/news/${_.slug}`,
      active: _.slug === pageHash || _.slug === pageSlug,
    })
  })

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout
            layoutStyle={LAYOUT_STYLE['leftNav']}
            links={links}
            smoothScroll={true}
            seo={{ title: detailPageTitle || types.title }}
            containerDivider={false}
            linksClassName={cx('links')}
            className={cx('page', transitionStatus, { initialPress: !inPress })}
          >
            <div className={cx('page-con', transitionStatus, { initialPress: !inPress })}>
              <div className={cx('content', transitionStatus, { initialPress: !inPress })}>{posts[0]}</div>
              <div
                className={cx('filters-con', { 'disp-none': isDetailPage }, transitionStatus, {
                  initialPress: !inPress,
                })}
              >
                <span className={cx('search-span')}>
                  <img className={cx('search-icon')} src="/images/ms/searchIconSmaller.png" alt="search" />
                  <input
                    type="text"
                    className={cx('search-bar')}
                    placeholder={'What are you looking for?'}
                    onChange={event => {
                      setSearchTerm(event.target.value)
                    }}
                  />
                </span>
                <div className={cx('date-filter-and-icon-con')}>
                  <img className={cx('search-icon')} src="/images/ms/calendar_icon_small.png" alt="date filter" />
                  <button
                    className={cx('date-filter-btn')}
                    onClick={() => {
                      togglePickerVisibility()
                    }}
                  >
                    {formatDate(dateRange[0].startDate)} - {formatDate(dateRange[0].endDate)}
                  </button>
                </div>
              </div>
              <div className={cx('date-picker-con', 'dp-identifier', 'disp-none')}>
                <DateRangePicker
                  className={cx('date-picker')}
                  onChange={item => {
                    setDateRange([item.selection])
                  }}
                  showSelectionPreview={true}
                  staticRanges={customRanges}
                  inputRanges={customInputRanges}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  retainEndDateOnFirstSelection={true}
                  months={1}
                  ranges={dateRange}
                  calendarFocus="backwards"
                  color="#9e6852"
                />
              </div>
              <div className={cx('cards-con', transitionStatus, { initialPress: !inPress })}>{posts.slice(1)}</div>
            </div>
          </Layout>
        )
      }}
    </TransitionState>
  )
}

PressPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  transitionStatus: PropTypes.string,
  data: PropTypes.object,
}

export default PressPage
